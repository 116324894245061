import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

const backURL = process.env.REACT_APP_BACK_URL

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('yariyan_gal'); // Retrieve the token from local storage or your preferred location

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                console.log("validate token", token);
                navigate("/")
                return;
            }
            try {
                const response = await fetch(backURL + '/validate_token', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}` // Send the JWT token in the header
                    },
                });

                if (!response.ok) {
                    console.error("Invalid token");
                    navigate("/");
                    return;
                } else {
                    console.log("token is valid");
                    const subscription = localStorage.getItem('subpurpose');
                    if (subscription) {
                        console.log("sub : ", subscription);
                        navigate("/" + subscription);
                        return;
                    }
                    navigate("/");
                    return;
                }
            } catch (error) {
                console.error("Error validating token:", error);
                navigate("/");
            }
        };

        validateToken();
    }, [navigate, token]);

    return children;
};

export default PrivateRoute;
