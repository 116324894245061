import React, { useEffect, useState } from 'react';
import './App.css'; // Main CSS file
// import { json, useNavigate, Navigate } from "react-router-dom";
import SignupModel from './SignupModel'; // Modal component
import LoginModel from './LoginModel';
// import { json, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

// const _ = require('lodash');
// const backURL = process.env.REACT_APP_BACK_URL

const Renderpage = () => {
    // const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('signup'); // Track which modal is open
    const [nav, setNav] = useState('');
    // const token = localStorage.getItem('yariyan_gal');
    const [greeting, setGreeting] = useState("");

    // const subscription = localStorage.getItem('subpurpose');

    // useEffect(() => {
    //     const validateToken = async () => {

    //         if (!token) {
    //             console.log("validate token", token);
    //             < Navigate to="/" />
    //             return;
    //         }
    //         try {
    //             const response = await fetch(backURL + '/validate_token', {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Authorization": `Bearer ${token}` // Send the JWT token in the header
    //                 },
    //             });

    //             if (!response.ok) {
    //                 console.error("Invalid token");
    //                 navigate("/");
    //                 return;
    //             } else {
    //                 console.log("token is valid");
    //                 const subscription = localStorage.getItem('subpurpose');
    //                 if (subscription) {
    //                     // sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    //                     console.log("sub : ", sanitizedSubscription);
    //                     navigate("/" + sanitizedSubscription);
    //                     return;
    //                 }
    //                 navigate("/");
    //                 return;

    //             }
    //         } catch (error) {
    //             console.error("Error validating token:", error);
    //             navigate("/");
    //         }
    //     };
    //     validateToken();
    // }, [navigate]);

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            setGreeting("Good Morning");
        } else if (currentHour < 18) {
            setGreeting("Good Afternoon");
        } else {
            setGreeting("Good Evening");
        }
    }, []);


    const openModal = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalType('');
    };

    const checkTrain = () => {
        try {
            setNav('train')
            openModal('login');
        }
        catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    const checkQuestion = () => {
        try {
            setNav('chat')
            openModal('login');

        }
        catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }

    }

    const checkAnalysis = () => {
        try {
            setNav('doc_upload');
            openModal('login');
        }
        catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    const checkImage = () => {
        try {
            setNav('img_analysis');
            openModal('login');
        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    const Advance_tool = () => {
        try {
            setNav('advance_tool');
            openModal('login');
        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    return (
        <div className="app">
            {/* Header with Sign Up and Login */}
            <header className="header">

                <div className="logo">
                    <img src="/images/vaani_logo.jpg" alt=" " className="logo-img" />
                    {/* <div>Vaani AI</div> */}
                </div>
                <div className="header-buttons">
                    <button className="header-btn" onClick={() => openModal('signup')}>
                        Sign Up
                    </button>
                    {/* <button className="header-btn" onClick={() => openModal('login')}>
                        Login
                    </button> */}
                </div>
            </header>

            {/* Main content */}
            <main className="main-content">
                <div className="main-text">  {greeting}! Select the option below. </div>
                <div className="card-container">
                    <div className="card" onClick={checkTrain}>
                        <h3>Training site</h3>
                        <p>I'm your trainer for your learning session. Lets explore together the different topics of your interest.</p>
                        <p>Select...</p>
                    </div>
                    <div className="card" onClick={checkQuestion}>
                        <h3>Question Answer</h3>
                        <p>I am an expert of the topics of your interest. Feel free to ask me different questions for your knowledge. </p>
                        <p>Select...</p>
                    </div>
                    {/* <div className="card" onClick={checkAnalysis}> */}
                    {/* <h3>Document Analysis</h3>
                        <p>I'm the document analyzer .I will help you for give answer of any document related question and csv/xls file analysis with generate a graph</p>
                        <p>Reply...</p>
                    </div> */}
                    <div className="card" onClick={checkImage}>
                        <h3>Safety Work Zone Analysis</h3>
                        <p>You plan to start your work. Let's check how safe your working zone is today</p>
                        <p>Select...</p>
                    </div>
                    {/* <div className="card" onClick={Advance_tool}>
                        <h2 className="blink">Advance Pro tool</h2>
                        <p>This is a advance pro tool for anlyze data from database,pdf,Excel,Website etc.</p>
                        <p>Reply...</p>
                    </div> */}
                </div>
            </main>

            {/* Conditionally render the modal based on modalType */}
            {isModalOpen && modalType === 'signup' && <SignupModel closeModal={closeModal} openModal={openModal} />}
            {isModalOpen && modalType === 'login' && <LoginModel closeModal={closeModal} openModal={openModal} getNav={nav} />}
        </div>
    );
};

export default Renderpage;
