import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Model.css'; // Modal-specific styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
const backURL = process.env.REACT_APP_BACK_URL;

const LoginModel = ({ closeModal, getNav }) => {
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [secure, setSecure] = useState({ user: 'AION@2023', pass: 'VA@05-07-2023' });
    const [cred, setCred] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForm = (e) => {
        // console.log(e.target.name);
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleNavigation = (path) => {
        navigate(path);
    };

    console.log('hi nav get : ', getNav);
    console.log("lang : ", form);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!form.language) {
                setErrorMessage('Please select a language.');
                return; // Prevent form submission
            }
            localStorage.setItem("lang", form.language);
            console.log(backURL)
            const response = await fetch(backURL + '/login', {
                method: 'POST',
                body: JSON.stringify({ ...form, ...secure }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            console.log("ssxx", data);
            if (data.user) {
                console.log("yes data get")
                setCred({
                    name: data.user.name
                });
                localStorage.setItem("yariyan_gal", data.access_token);
                localStorage.setItem("cred", JSON.stringify(cred));
                checkLogin();
            }
            else {
                toast.error(data.msg);
            }
        } catch (err) {
            console.log("error:", err)
            toast.error("something went wrong");
        }
    }
    // console.log("cred in", cred);

    const checkLogin = async () => {
        console.log("function check login call hua.");
        const token = localStorage.getItem("yariyan_gal");
        if (token) {
            console.log("hello ready for go navigate");
            console.log("hi getnav : ", getNav);
            if (getNav != '') {
                localStorage.setItem("subpurpose", getNav);
                navigate('/' + getNav);
            } else {
                localStorage.setItem("subpurpose", 'chat');
                navigate('/chat');
            }
        } else {
            console.log('something went wrong');
            // toast.error("something went wrong");
        }
    }

    const [languages, setLanguages] = useState([]);

    // Fetch languages from an API
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await fetch(backURL + "/languageGet"); // Replace with your API endpoint
                if (!response.ok) {
                    throw new Error("Failed to fetch languages");
                }
                const data = await response.json();
                setLanguages(data.msg); // Assume data is an array of language objects
                console.log('languages : ', data.msg);
            } catch (error) {
                console.error("Error fetching languages:", error);
            }
        };

        fetchLanguages();
    }, []);

    return (
        <div className="modal-backdrop">
            <ToastContainer position="top-left" autoClose={4000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <div className="modal_login">
                <button className="close-btn" onClick={closeModal}>
                    &times;
                </button>
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className='modal-form'>

                    <div className="form-group">
                        <input
                            type="text" // Changed from "email" to "text"
                            name="email" // Changed from "email" to "username"
                            value={form.username} // Updated to match the new "username" field in your form state
                            onChange={handleForm}
                            placeholder="Username (Email or Phone)"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={form.password}
                                onChange={handleForm}
                                placeholder="Password"
                                required
                                className="modal-input"
                                style={{ paddingRight: "2.5rem" }} // Add padding to accommodate the icon
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: "absolute",
                                    right: "0.5rem",
                                    cursor: "pointer",
                                    fontSize: "1.25rem",
                                    color: "#888",
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        {/* <label htmlFor="language">Choose Language:</label> */}
                        <select
                            id="language"
                            name="language"
                            value={form.language}
                            onChange={handleForm}
                            className="modal-input language-dropdown"
                            required
                        >
                            <option value="">Select Language</option>
                            {languages && (
                                languages.map((lang) => (
                                    <option key={lang.lang_code} value={lang.lang_code}>
                                        {lang.language}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    <button type="submit" className="auth-button">Submit</button>
                    {/* <button type="button" className="auth-button" onClick={() => handleNavigation("/sendotp")}>Forgot Password</button> */}
                </form>
                <p className="privacy-policy">
                    {/* Don't have Account? Create <a href="/render">Signup</a>. */}
                    {/* Don't have Account? Create <a href="/render">Signup</a>. */}
                </p>
            </div>
        </div>
    );
};

export default LoginModel;
