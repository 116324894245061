import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2, PlaneGeometry } from 'three';
import ReactAudioPlayer from 'react-audio-player';
import { createAnimation_train } from '../converter';
import blinkData from '../blendDataBlink.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import axios from 'axios';
import './App.css';
import * as THREE from 'three';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { json, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FaArrowLeft, FaArrowRight, FaMicrophone, FaMicrophoneSlash, FaPaperPlane, FaComments } from "react-icons/fa";
import Swal from 'sweetalert2';
import QuizComponent from './QuizComponent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more

const _ = require('lodash');
const backURL = process.env.REACT_APP_BACK_URL

function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const BlendData = blendData.blendData
  let filename = blendData.filename

  const [
    bodyDiffuseTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // hairDiffuseTexture,
    suitDiffuseTexture,
    suitNormalTexture,
    suitRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,

  ] = useTexture([
    "/train/body_diffuse.jpg",
    "/train/eyes.jpg",
    "/train/teeth_diffuse.jpg",
    "/train/body_specular.jpg",
    "/train/body_roughness.jpg",
    "/train/body_normal.jpg",
    "/train/teeth_normal.jpg",
    // "/train/h_diffuse.jpg",
    "/train/suit_diffuse.jpg",
    "/train/suit_normal.jpg",
    "/train/suit_roughness.jpg",
    "/train/h_alpha.jpg",
    "/train/h_normal.jpg",
    "/train/h_roughness.jpg",
  ]);

  _.each([
    bodyDiffuseTexture,
    eyesTexture,
    teethTexture,
    teethNormalTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    // hairDiffuseTexture,
    suitDiffuseTexture,
    suitNormalTexture,
    suitRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ],
    t => { t.encoding = sRGBEncoding; t.flipY = false; });

  bodyNormalTexture.encoding = LinearEncoding;
  suitNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding

  gltf.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;
      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;
        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyDiffuseTexture;
        node.material.envMapIntensity = .7;
        node.material.roughness = 5;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.8, 0.8);

        morphTargetDictionaryBody = node.morphTargetDictionary;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;

      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.9;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        // node.material.map = hairDiffuseTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;
        node.material.roughness = 25;

        node.material.transparent = true;
        node.material.opacity = 3;
        node.material.depthWrite = false;
        node.material.side = 0;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("Suit")) {
        node.material = new MeshStandardMaterial();

        node.material.map = suitDiffuseTexture;
        node.material.roughnessMap = suitRoughnessTexture;
        node.material.roughness = 4;
        node.material.normalMap = suitNormalTexture;
        // node.material.color.setHex(0xffffff);
        // node.material.envMapIntensity = 1;
      }


      if (node.name.includes("Goggel_body")) {
        node.material = new MeshStandardMaterial({
          color: 0x000000,
          roughness: 0.7,
          metalness: 0.5,
        });
      }

      if (node.name.includes("Goggel_glass")) {
        node.material = new MeshStandardMaterial({
          color: 0xffffff,
          transparent: true,
          opacity: 0.3,
          roughness: 0.1,
          metalness: 0,
        });
      }


      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

    console.log('hhhh', filename);
    if (BlendData.length > 0) {
      let newClips = [createAnimation_train(BlendData, morphTargetDictionaryBody, 'HG_Body'),
      createAnimation_train(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

      filename = backURL + filename;

      setClips(newClips);
      setAudioSource(filename);
    } else {
      filename = backURL + filename;
      setAudioSource(filename);
    }
  }, [speak]);



  let idleFbx = useFBX('/stand.fbx');
  let speakFbx = useFBX('/speak.fbx'); // Load the speak animation
  let { clips: idleClips } = useAnimations(idleFbx.animations);
  let { clips: speakClips } = useAnimations(speakFbx.animations);

  // Track current and previous actions for blending
  let currentAction = useRef(null);
  let previousAction = useRef(null);

  useEffect(() => {
    if (gltf.scene) {
      // Set the initial position, rotation, and scale of the character
      gltf.scene.position.set(-0.10, 0.8, 0);
      gltf.scene.rotation.x = THREE.MathUtils.degToRad(-90);
      gltf.scene.scale.set(0.6, 0.6, 0.6);

      // Play the initial idle animation
      let idleClipAction = mixer.clipAction(idleClips[0], gltf.scene);
      idleClipAction.play();
      currentAction.current = idleClipAction;

      // Create and play the blink animation
      let blinkClip = createAnimation_train(blinkData, morphTargetDictionaryBody, 'HG_Body');
      let blinkAction = mixer.clipAction(blinkClip, gltf.scene);
      blinkAction.play();
    }
  }, []);

  useEffect(() => {
    if (gltf.scene) {
      // Determine the new animation to play
      let newAction = speak
        ? mixer.clipAction(speakClips[0], gltf.scene) // Speak animation
        : mixer.clipAction(idleClips[0], gltf.scene); // Idle animation

      // Blend from the previous action to the new action
      if (currentAction.current && currentAction.current !== newAction) {
        previousAction.current = currentAction.current;
        previousAction.current.fadeOut(0.5); // Smoothly fade out the previous animation
      }

      newAction.reset().fadeIn(0.5).play(); // Smoothly fade in the new animation
      currentAction.current = newAction;
    }
  }, [speak]);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false) return;

    const actions = _.map(clips, (clip) => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
      return clipAction;
    });

    setClipActions(actions);
  }, [playing]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );

}

const STYLES = {
  area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
  speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
  stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
  label: { color: '#777777', fontSize: '0.5em' }
}

function Train() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [blendData, setBlendData] = useState({});
  const [audioSource, setAudioSource] = useState(null);
  const [speak, setSpeak] = useState(false);
  const [playing, setPlaying] = useState(false);
  const audioPlayer = useRef();
  const [clipActions, setClipActions] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [relatedOptions, setRelatedOptions] = useState([]);
  const [analyzeImage, setAnalyzeImage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [text, setText] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [quizModalVisible, setQuizModalVisible] = useState(false);
  const [showStartQuizPopup, setShowStartQuizPopup] = useState(false);
  const [submodule, setSubmodule] = useState('');
  const [chatMessages, setChatMessages] = useState([]); // Stores chat history
  const [startIndex, setStartIndex] = useState(); // for store start index
  const [endIndex, setEndIndex] = useState(); // for store end index
  const [msg, setMsg] = useState("");
  const [isListening, setIsListening] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false); // To toggle the chat window
  const audioChunksRef = useRef([]);
  const chatMessagesRef = useRef(null);
  const textRef = useRef(null);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  const token = localStorage.getItem('yariyan_gal');
  // const language = localStorage.getItem('lang');
  const [language, setLanguage] = useState(localStorage.getItem("lang") || "");
  console.log("lnnx :", language);
  const subscription = localStorage.getItem('subpurpose');

  const bhashini = {
    "bn": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "en": "ai4bharat/whisper-medium-en--gpu--t4",
    "gu": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "hi": "ai4bharat/conformer-hi-gpu--t4",
    "kn": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "ml": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "mr": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "or": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "pa": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "sa": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
    "ta": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "te": "ai4bharat/conformer-multilingual-dravidian-gpu--t4",
    "ur": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4"

  }

  useEffect(() => {
    if (token && subscription != null) {
      if (subscription == 'train') {
        const greetingMessage = 'Welcome to the training site you can ask any topic.';

        getResposnse(greetingMessage);
        getSUbcriptions();
      } else {
        navigate('/' + subscription);
        return;
      }
    } else {
      console.log("hello guys something missing!");
      navigate("/");
      return;
    }
  }, [])

  const handleLangChange = async (e) => {
    e.preventDefault();
    const selectedLang = e.target.value;
    setLanguage(selectedLang);
    localStorage.setItem("lang", selectedLang);
  }

  useEffect(() => {
    // Sync state with localStorage on component mount
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLanguage(storedLang);
    }
  }, []); // Runs only once

  const getSUbcriptions = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
      };
      const response = await fetch(backURL + "/getSubscription", requestOptions); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Failed to fetch subscriptions.");
      }
      const data = await response.json();
      console.log('subscriptions : ', data.response);
      setSubscriptions(data.response);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  }

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
  };

  const odiyaTranslate = async (audioBlob) => {
    console.log("Calling Bhashini API...");

    const audioBase64 = await convertBlobToBase64(audioBlob);

    const url = "https://dhruva-api.bhashini.gov.in/services/inference/pipeline"; // Bhashini API endpoint
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': '6MPVGIKTkGYAgvfLCEu5DQ3xTzSS_iGK6A7BSNSYl8fkquoVTspInRSPC6bofxe2',
    };

    const payload = JSON.stringify({
      "pipelineTasks": [
        {
          "taskType": "asr",
          "config": {
            "language": {
              "sourceLanguage": language // Odia language code
            },
            "serviceId": bhashini[language],
            "audioFormat": "webm", // Change to webm format
            "samplingRate": 16000
          }
        }
      ],
      "inputData": {
        "audio": [
          {
            "audioContent": audioBase64 // Base64 encoded audio
          }
        ]
      }
    });

    try {
      const response = await axios.post(url, payload, { headers });
      if (response.data) {
        console.log("bhasini ended : ")
        const translatedText = response.data.pipelineResponse[0].output[0].source;
        console.log("ttt : ", translatedText)
        setMsg(translatedText);
      }
    } catch (error) {
      console.error('Error translating audio:', error.response ? error.response.data : error);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Return only the base64 part
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const silenceTimeoutRef = useRef(null); // To keep track of the timeout

  const startListening = async () => {
    console.log("hello languag is  : ", language);
    audioChunksRef.current = [];

    // Request access to the microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await odiyaTranslate(audioBlob);
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (mediaRecorderRef.current) {
      setIsListening(false);
      console.log("stop call hua");
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    clearTimeout(silenceTimeoutRef.current);
  };

  useEffect(() => {
    if (isListening) {
      console.log("lang : ", language);
      startListening();
      silenceTimeoutRef.current = setTimeout(() => {
        console.log("Auto-stopping after 15 seconds of inactivity.");
        stopListening(); // Automatically stop listening after 15 seconds
      }, 15000);
    } else {
      stopListening();
    }
  }, [isListening]);

  const stopButton = async () => {
    try {

      console.log('Stop button clicked');
      audioPlayer.current.audioEl.current.pause();
      setPlaying(false);
      setAudioSource(null);
      setSpeak(false);
      if (browserSupportsSpeechRecognition && SpeechRecognition.getRecognition()) {
        SpeechRecognition.stopListening();
      }
      clipActions.forEach(action => {
        action.stop();  // Stop each action
      });

      setClipActions([]);  // Clear the actions
    } catch (e) {
      console.log("Error occured :", e);
    }
  }

  const getResposnse = async (welcome) => {
    try {
      console.log("Response: ", welcome);
      setLoad(true);
      const requestOptions = {
        method: 'POST',
        headers:
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },

        body: JSON.stringify({ text: welcome, language: `${language}`, sound: isSoundOn }),
      };
      try {
        const response = await fetch(`${backURL}/train`, requestOptions);
        const result = await response.json();
        console.log("get result : ", result)
        if (result['status'] !== 'failed') {
          setBlendData(result);
          setSpeak(true);
        } else {
          console.log("sound if off");
          // toast.error("Something else");
        }
      } catch (error) {
        toast.error("Error generating response")
        console.error('Error generating response:', error);
      }
      setLoad(false);
    } catch (e) {
      console.log("Something else : ", e);
    }
  };

  const autoResize = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleNavigation = async (e) => {
    e.preventDefault();
    localStorage.setItem("subpurpose", e.target.value);
    navigate('/' + e.target.value);
  }

  // fetch the tarin topic data
  const fetchTrain = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers:
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      try {
        const response = await fetch(`${backURL}/trainData`, requestOptions);
        const result = await response.json();
        console.log("get train data : ", result.response);
        if (result) {
          console.log('result h bhai .')
          setTopics(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
        console.error('Error generating response:', error);
      }
      setLoad(false);
    } catch (e) {
      console.log("Something else : ", e);
    }
  };

  function playerEnded() {
    try {
      setSpeak(false);
      setPlaying(false);
      setAudioSource(null);

    } catch (e) {
      console.log('Error occured : ', e)
    }
  }


  function playerReady() {
    try {
      const playPromise = audioPlayer.current.audioEl.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setPlaying(true);
            if (browserSupportsSpeechRecognition) {
              SpeechRecognition.stopListening();  // Stop listening while audio is playing
            }
          })
          .catch((error) => {
            console.log("Audio play was prevented. User hasn't interacted yet.", error);
            alert("Please interact with the page (e.g., click a button) to allow audio playback.");
          });
      }
    } catch (e) {
      console.log("Something else : ", e);
    }
  }

  const handleLogout = async () => {
    try {
      setPlaying(null);
      localStorage.clear(); // Clears all session storage
      navigate("/render");
      // const response = await fetch(backURL + '/logout', {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Authorization": `Bearer ${token}` // Send the JWT token in the header
      //   },
      // });
      // const data = await response.json();
      // console.log(data); // Log response from server
      // if (data.status == 'success') {
      //   localStorage.clear(); // Clears all session storage
      //   navigate("/render");
      // } else {
      //   toast.error("something went wrong!");

      // }
    } catch (e) {
      toast.error("Some thing went wrong!");
      console.log("error in logout: ", e);
    }
  };


  const getTopicResponse = async (question) => {
    try {
      if (!submodule && !selectedTopic) {
        toast.error("Please select the topic");
        throw new Error("Please select the topic");
      }
      if (question === '') {
        toast.error("Prompt can't be empty.");
        return;
      }

      const toast_train = toast.loading("Please wait...");
      const userMessage = {
        type: "question",
        text: question,
        stepIndex: currentStepIndex // Attach question to the current step
      };
      setChatMessages((prevMessages) => [...prevMessages, userMessage]);

      setLoad(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          fileId: selectedTopic,
          language: `${language}`,
          question: question,
          sound: isSoundOn
        }),
      };

      try {
        const response = await fetch(`${backURL}/getTopic`, requestOptions);
        const result = await response.json();
        console.log("get result question : ", result);

        if (result['status'] !== 'failed') {
          toast.update(toast_train, {
            render: "Success!",
            type: "success",
            isLoading: false,
            autoClose: 500
          });

          const aiResponse = {
            type: "answer",
            text: Array.isArray(result) ? result[0] : `${result}`,
            stepIndex: currentStepIndex // Attach response to the current step
          };

          setChatMessages((prevMessages) => [...prevMessages, aiResponse]);

          if (Array.isArray(result)) {
            setBlendData(result[1]);
            setSpeak(true);
          }

          setMsg("");
        } else {
          toast.update(toast_train, {
            render: "Response Failed !",
            type: "error",
            isLoading: false,
            autoClose: 2000
          });
        }
      } catch (error) {
        console.error('Error generating response:', error);
      }
      setLoad(false);
    } catch (e) {
      console.log("Something else : ", e);
    }
  };


  useEffect(() => {
    if (selectedTopic !== "") {
      fetchSubModule(selectedTopic);
      setChatMessages([]);
      setText([]);
      setStartIndex();
      setEndIndex();
      setCurrentStepIndex(0);
    }
  }, [selectedTopic]);

  const fetchSubModule = async (selectedTopic) => {
    console.log(selectedTopic);
    const requestOptions = {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

      body: JSON.stringify({ file_id: selectedTopic }),
    };
    try {
      const response = await fetch(`${backURL}/trainsubModule`, requestOptions);
      const result = await response.json();
      if (result) {
        console.log('sub res :', result.submodule_List);
        setRelatedOptions(result.submodule_List);
      } else {
        console.log('sub module list is not present');
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleConfirmStep = async (confirmed) => {
    try {
      if (confirmed) {
        console.log('confirm step : ', startIndex)
        const start = startIndex + 1;
        setStartIndex(start);
        getNextStep(start);

        if (startIndex < endIndex) {
          console.log('confirm step : ', currentStepIndex);
          setCurrentStepIndex(currentStepIndex + 1); // Move to the next step
          setShowPopup(true); // Show popup for the next step
        } else {
          setShowStartQuizPopup(false);
          Swal.fire({
            title: "Congratulations!",
            text: "You have completed all steps!",
            icon: "success",
            confirmButtonText: "Awesome!",
            confirmButtonColor: "#4CAF50",
          });
        }
      } else {
        Swal.fire({
          title: "Step Canceled",
          text: "You have chosen to cancel this step.",
          icon: "info",
          confirmButtonText: "OK",
          confirmButtonColor: "#f44336",
        });
        setStartIndex(startIndex + 1);
      }

      setQuizModalVisible(false)
      setShowStartQuizPopup(false);
      setShowPopup(false);
    } catch (e) {
      console.log("some thing error in handle step : ", e);
    }
  };

  const getNextStep = async (index = null) => {
    console.log("getNextStep: ", index);
    const toast_module = toast.loading("Please wait...");
    const requestOptions = {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

      body: JSON.stringify({ file_id: selectedTopic, submodule: submodule, index: index, language: `${language}` }),
    };
    try {
      const response = await fetch(`${backURL}/module-content`, requestOptions);
      const result = await response.json();
      toast.update(toast_module, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
      console.log('sub mod :', result[0]);
      setQuizModalVisible(false);
      setText((prevText) => [...prevText, result]);
      // setBlendData(result[1]);
      // setSpeak(true);
      if (result.startIndex) {
        setCurrentStepIndex(0);
        setStartIndex((result.startIndex) - 1);
        setEndIndex(result.endIndex);
      }
    } catch (err) {
      toast.update(toast_module, { render: "Response Failed !", type: "error", isLoading: false, autoClose: 1000 });
      console.error(err);
    }
  }

  // const handlePreviousStep = () => {
  //   try {
  //     if (currentStepIndex > 0) {
  //       setCurrentStepIndex(currentStepIndex - 1); // Go back to the previous step
  //       setStartIndex(startIndex - 1);
  //     }
  //     setShowPopup(false); // Close popup
  //   } catch (e) {
  //     console.log("some thing error in handle previous :", e);
  //   }
  // };


  console.log('start and end : ', startIndex, endIndex);

  const handleOpenImage = (image) => {
    try {
      console.log('open function call hua');
      setAnalyzeImage(image);
      setIsOpen(true);
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };

  console.log('ss: ', currentStepIndex);

  const handleConfirmVideo = (confirmed) => {
    try {
      if (confirmed) {
        console.log('yes you are confirmed');
      }
      setShowVideoPopup(false);
      // setShowPopup(true);
    } catch (e) {
      console.log("some thing error in handleconfirm vidoe :", e);
    }
  };

  // Function to handle media steps (image or video)
  const handleMediaStep = (step) => {
    console.log('sss : ', backURL + step.media_path);
    console.log("handleMediaStep: ", step);
    if (step.media_type === 'image') {
      return (
        <div className='steps' onClick={() => handleOpenImage(backURL + step.media_path)}>
          {/* <img src={step.media_path} alt={step.submodule} style={{ maxWidth: '95%' }} /> */}
          <img src={backURL + step.media_path} alt={step.submodule} style={{ maxWidth: '95%' }} />
          <p>{step.content}</p>
          {isOpen && (
            <Lightbox
              mainSrc={analyzeImage}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </div>

      );
    }
  };

  const handleCloseQuiz = () => {
    setQuizModalVisible(false);
    setShowPopup(true);
  };

  useEffect(() => {
    const handleSteps = async () => {
      try {
        console.log('handle current :', currentStepIndex)
        console.log('handle text : ', text);
        // Check for a "question" step at the current index
        const questionStep = text.some(
          (step, index) => step.step === 'question' && index === currentStepIndex
        );
        console.log("question step : ", questionStep);
        if (questionStep) {
          await getResposnse("Are you ready for take a Quiz?"); // Wait for the response
          setShowStartQuizPopup(true); // Show popup after response
        }

        // Check for a step with media_type 'video' at the current index
        const videoStep = text.find(
          (step, index) => step.media_type === 'video' && index === currentStepIndex
        );
        if (videoStep) {
          setVideoUrl(backURL + videoStep.media_path);
          setShowVideoPopup(true);
        }

      } catch (e) {
        toast.error("Something went wrong!");
        console.error(e);
      }
    };
    if (startIndex <= endIndex) {
      handleSteps(); // Call the asynchronous function inside useEffect
    }
  }, [text, currentStepIndex]);


  const handleRelatedOptionChange = async (value) => {
    console.log(value);
    setText([]);
    setStartIndex();
    setEndIndex();
    setCurrentStepIndex(0);
    setSubmodule(value);
  };

  useEffect(() => {
    if (submodule) {
      console.log("Submodule updated:", submodule);
      getNextStep(); // Call the function whenever `submodule` changes
    }
  }, [submodule]); // Runs whenever `submodule` state changes

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.scrollTo({
        top: textRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [text, currentStepIndex]);

  const [languages, setLanguages] = useState([]);

  // Fetch languages from an API
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(backURL + "/languageGet"); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setLanguages(data.msg); // Assume data is an array of language objects
        console.log('languages : ', data.msg);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
    fetchTrain();
  }, []);

  console.log("text : ", text);
  return (
    <div className="full">
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out`}>
        <div className="p-4 flex justify-between items-center">
          <button onClick={toggleSidebar} className="text-white">
            <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              toggleSound();
            }}
            className="ml-4 text-lg flex items-center"
          >
            <FontAwesomeIcon icon={isSoundOn ? faVolumeUp : faVolumeMute} />
          </button>
          {isSidebarVisible && (
            <div className="flex items-center">
              <button onClick={handleLogout} className="text-sm flex items-center">
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Logout
              </button>
            </div>
          )}
        </div>

        {isSidebarVisible && (
          <div className="p-4">
            <select
              id="path"
              name="path"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              onChange={handleNavigation}
            >
              <option value={subscription}>Navigate To</option>
              {subscriptions &&
                subscriptions.map((subscr) =>
                  subscr.featureValue !== subscription ? (
                    <option key={subscr.featureValue} value={subscr.featureValue}>
                      {subscr.featureName}
                    </option>
                  ) : null
                )}
            </select>
            <select
              id="language"
              name="language"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              value={language} // Bind the value here
              onChange={handleLangChange} // Use onChange for better form handling
            >
              <option value="">Select Language</option> {/* Placeholder option */}
              {languages &&
                languages.map((lang) => (
                  <option key={lang.lang_code} value={lang.lang_code}>
                    {lang.language}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div>
          {/* Button to open the chat window */}
          <button
            className="chat-open-btn"
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            Ask Question
            <FaComments size={10} color="#007bff" /> {/* Chat icon */}
          </button>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 flex-col">
        <div style={STYLES.area}>
          <button style={STYLES.speak}>
            {speak || load ? 'Thinking...' : 'Type message.'}
          </button>
          {speak && (
            <button style={STYLES.stop} onClick={stopButton}>
              Stop
            </button>
          )}
        </div>

        <div className="train-div">
          <div>
            {/* First Dropdown */}
            <div className="topic-dropdown">
              {/* <label htmlFor="topic-select">Select A Topic:</label> */}
              <select
                id="topic-select"
                value={selectedTopic || ""}
                onChange={(e) => setSelectedTopic(e.target.value)}
                className="dropdown"
              >
                <option value="" disabled>
                  Select a topic
                </option>
                {topics && (
                  topics.map((topic, index) => (
                    <option key={index} value={topic.fileId}>
                      {topic.moduleName}
                    </option>
                  ))
                )}
              </select>
            </div>

            {/* Second Dropdown */}
            {relatedOptions.length > 0 && (
              <div className="related-dropdown">
                {/* <label htmlFor="related-select">Select Sub Module:</label> */}
                <select
                  id="related-select"
                  className="dropdown"
                  value={submodule}
                  onChange={(e) => handleRelatedOptionChange(e.target.value)}
                >
                  <option value="" disabled>Select Sub Module</option>
                  <option value="all">Complete Module</option>
                  {relatedOptions
                    .filter((option) => option.trim() !== '') // Filter out empty or blank strings
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
          {/* Display topic description */}
          {text.length > 0 && (
            <div className="topic-details" ref={textRef}>
              {text.map((step, index) => (
                index <= currentStepIndex && (
                  <div key={index} className="step-container">
                    {step.submodule === 'Purpose' && (
                      <div className="desc">
                        Description : {step.content}
                        <b>Total Steps: {Math.max(0, endIndex - startIndex)}</b>
                      </div>
                    )}
                    {step.step === 'media' && handleMediaStep(step)}

                    {step.step === 'instruction' && step.submodule !== 'Purpose' && (
                      <div className="steps">
                        <p>Step {index}: {step.content}</p>
                      </div>
                    )}

                    {step.step === 'question' && (
                      <>
                        {quizModalVisible && (
                          <QuizComponent step={step} onCloseQuiz={handleCloseQuiz} />
                        )}
                      </>
                    )}

                    {/* Render associated chat messages */}
                    <div className="chat-messages" ref={chatMessagesRef}>
                      {chatMessages
                        .filter(message => message.stepIndex === index) // Filter messages for the current step
                        .map((message, messageIndex) => (
                          <div
                            key={messageIndex}
                            className={`chat-train-message ${message.type === "question" ? "user-messages" : "ai-message"
                              }`}
                          >
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                              {message.text}
                            </ReactMarkdown>
                          </div>
                        ))}
                    </div>
                  </div>
                )
              ))}
            </div>
          )}

          {showPopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                <p className="popup-question">Do you want to proceed with Step {currentStepIndex + 1} . </p>
                <div className="popup-buttons">
                  <button className="popup-button cancel" onClick={() => handleConfirmStep(false)}>No</button>
                  <button className="popup-button confirm" onClick={() => handleConfirmStep(true)}>Yes</button>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Icons */}
          <div className="navigation-buttons">
            {startIndex > 0 && startIndex <= endIndex && (
              <FaArrowRight
                className="nav-icon next"
                onClick={() => {
                  if (startIndex === endIndex) {
                    handleConfirmStep(true); // Call the function if startIndex equals endIndex
                  } else {
                    setShowPopup(true); // Otherwise, show the popup
                  }
                }}
              />
            )}
          </div>

          {/* Input Message Box Below Navigation Buttons */}
          {isChatOpen && (
            <div className="chat-box-fixed">
              <button
                className="msgbtn"
                id="mic"
                onClick={() => setIsListening(!isListening)} // Toggle mic state
              >
                <span className="tooltip-text">Tap to {isListening ? "stop" : "talk"}</span>
                {isListening ? (
                  <FaMicrophone size={20} color="#007bff" /> // On-mic icon
                ) : (
                  <FaMicrophoneSlash size={20} color="#ff0000" /> // Off-mic icon
                )}
              </button>
              <textarea
                className="text-area"
                value={msg}
                onChange={(e) => {
                  setMsg(e.target.value);
                  autoResize(e.target);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevents adding a new line
                    getTopicResponse(msg);
                  }
                }}
                placeholder="Say Hello!"
              />

              <button className="msgbtn" id="send" onClick={() => getTopicResponse(msg)}>
                <FaPaperPlane size={20} color="#007bff" />
              </button>

            </div>
          )}
        </div>

        {/* Popup for Video Confirmation */}
        {showVideoPopup && (
          <div className="popup">
            <div className="popup-video">
              <span className="close-video" onClick={() => handleConfirmVideo(false)}>
                &times;
              </span>
              <p>Do you want to see this video?</p>
              {videoUrl && (
                <video width="400" height="300" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        )}

        {showStartQuizPopup && (
          <div className="confirmation-popup-overlay">
            <div className="confirmation-popup-content">
              <h3>Start Quiz</h3>
              <p>Are you ready to start the quiz?</p>
              <div className="confirmation-buttons">
                <button
                  className="confirm-button"
                  onClick={() => {
                    setQuizModalVisible(true);
                    setShowStartQuizPopup(false); // Close confirmation modal
                  }}
                >
                  Yes
                </button>
                <button
                  className="cancel-button"
                  onClick={() => setShowStartQuizPopup(false)} // Close confirmation modal
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
      />
      <Canvas
        dpr={2}
        onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}
      >
        <OrthographicCamera makeDefault zoom={1300} position={[0, 1.65, 1]} />
        <Suspense fallback={null}>
          <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
        </Suspense>
        <Suspense fallback={null}>
          <Bg />
        </Suspense>
        <Suspense fallback={null}>
          <Avatar
            avatar_url="/rohit.glb"
            speak={speak}
            setSpeak={setSpeak}
            setAudioSource={setAudioSource}
            playing={playing}
            blendData={blendData}
            setClipActions={setClipActions}
          />
        </Suspense>
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  );
}

function Bg() {

  const texture = useTexture('/images/training.png');

  return (
    <mesh position={[0, 1.8, -1]} scale={[1.2, 0.9, 1]}>
      <planeGeometry args={[]} />
      <meshBasicMaterial map={texture} />

    </mesh>
  )
}
export default Train;