import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../Chat/App.css';
import '../Document Analysis/doc.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import rehypeRaw from 'rehype-raw'; // To allow raw HTML rendering
const backURL = process.env.REACT_APP_BACK_URL

const ImageAnal = () => {
    const navigate = useNavigate();
    // const [authenticated, setAuthenticated] = useState(false);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [chatHistory, setChatHistory] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chatId, setChatId] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [analyzeImage, setAnalyzeImage] = useState('');
    const fileInputRef = useRef(null);
    const [imageAnalyses, setImageAnalyses] = useState([]);
    const [msgPage, setMsgPage] = useState(1);
    const [page, setPage] = useState(1);
    const [endPage, setEndPage] = useState();
    const [subscriptions, setSubscriptions] = useState([]);


    // const cred = JSON.parse(localStorage.getItem("cred") || "[]");
    // const userId = cred?.uid;
    // const useremail = cred?.email;
    const token = localStorage.getItem('yariyan_gal');
    const subscription = localStorage.getItem('subpurpose');
    let language = localStorage.getItem('lang');

    useEffect(() => {
        if (token && subscription != null) {
            if (subscription == 'img_analysis') {
                setChatHistory([]);
                fetchChatHistoryByUserId();
                getSUbcriptions();

            } else {
                navigate('/' + subscription);
                return;
            }
        } else {
            console.log("hello guys something missing!");
            navigate("/");
            return;
        }
    }, [])


    const fetchChatHistoryByUserId = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ subscription: subscription, page: page })
            };
            const response = await fetch(backURL + '/latestChat', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            console.log('chat is : ', data);
            if (data) {
                setChatHistory((prevChatHistory) => [...prevChatHistory, ...data.top_cids]);
                setEndPage(data.total_pages);
            }

        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const getSUbcriptions = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
                },
            };
            const response = await fetch(backURL + "/getSubscription", requestOptions); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error("Failed to fetch subscriptions.");
            }
            const data = await response.json();
            console.log('subscriptions : ', data.response);
            setSubscriptions(data.response);
        } catch (error) {
            console.error("Error fetching languages:", error);
        }
    }


    const fetchMessagesByChatOrUserId = async (cid) => {
        try {
            setChatId(cid);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ chat_id: cid, page: msgPage })
            };
            const response = await fetch(backURL + '/fetchmessages', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch messages');
            }

            const mess = await response.json();
            console.log("history:  ", mess.messages);
            setMessages(mess.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleLogout = async () => {
        try {
            localStorage.clear(); // Clears all session storage
            navigate("/render");
            // const response = await fetch(backURL + '/logout', {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${token}` // Send the JWT token in the header
            //     },
            // });
            // const data = await response.json();
            // console.log(data); // Log response from server
            // if (data.status == 'success') {
            //     localStorage.clear(); // Clears all session storage
            //     navigate("/render");
            // } else {
            //     toast.error("something went wrong!");
            // }
        } catch (e) {
            toast.error("Some thing went wrong!");
            console.log("error in logout : ", e);
        }
    };

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const handleAnalyseOpenImage = (image) => {
        try {
            console.log('open function call hua');
            setAnalyzeImage(image);
            setIsOpen(true);
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const nextChat = () => {
        try {
            const next = page + 1
            setPage(next);
            fetchChatHistoryByUserId();
        } catch (e) {
            console.log("Error occured : ", e);
        }
    }


    const groupByDate = (data) => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        const grouped = {
            today: [],
            lastSevenDays: [],
            older: []
        };

        data.forEach(item => {
            const itemDate = new Date(item.latest_time);

            if (itemDate.toDateString() === today.toDateString()) {
                grouped.today.push(item);
            } else if (itemDate >= sevenDaysAgo) {
                grouped.lastSevenDays.push(item);
            } else {
                grouped.older.push(item);
            }
        });

        return grouped;
    };

    console.log('chat : ', chatHistory);

    const groupedData = groupByDate(chatHistory);
    console.log('group : ', groupedData);

    const renderChats = (title, chats) => {
        if (chats.length === 0) return null;

        return (
            <div className="chat-group">
                <h4 className="font-bold">{title}</h4>
                {chats.map((chat) => (
                    <div key={chat.cid} className="chat-item relative">
                        <div className="chat-message-container">
                            <p
                                className="chat-heading"
                                onClick={() => fetchMessagesByChatOrUserId(chat.cid)}
                            >
                                {chat.title || "Untitled Chat"}
                            </p>
                            <span
                                className="dots-icon"
                                onClick={() => toggleDropdown(chat.cid)}
                            >
                                &#x2026;
                            </span>
                        </div>
                        {dropdownVisible === chat.cid && (
                            <div className="dropdown-menu">
                                <ul className="py-1">
                                    <li onClick={() => handleChatAction("delete", chat.cid)}>
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const [dropdownVisible, setDropdownVisible] = useState(null);

    const toggleDropdown = (cid) => {
        setDropdownVisible(dropdownVisible === cid ? null : cid);
    };

    const handleChatAction = (action, chatId) => {
        if (action === "delete") {
            deleteChat(chatId);
        }
        setDropdownVisible(null);
    };

    const deleteChat = async (cid) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ chat_id: cid })
            };
            const response = await fetch(backURL + '/deleteChat', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to delete chat');
            }

            const mess = await response.json();
            toast.error(mess.response);
            setTimeout(() => {
                // setQuest([]);
                // setChats([]);
                setMessages([]);
                setChatHistory([]);
                fetchChatHistoryByUserId();
            }, 2000);
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    const handleNewChat = () => {
        const newChatId = uuidv4();
        console.log('new chat : ', newChatId);
        setChatId(newChatId);
        // setQuest([]);
        // setChats([]);
        setMessages([]);
        setImageAnalyses([]);
    };

    const handleLangChange = async (e) => {
        e.preventDefault();
        localStorage.setItem("lang", e.target.value);
    }

    const handleNavigation = async (e) => {
        e.preventDefault();
        localStorage.setItem("subpurpose", e.target.value);
        navigate('/' + e.target.value);
    }

    language = localStorage.getItem('lang');

    // const handleImageUpload = async (event) => {
    //     const toastgen = toast.loading("Please wait...");
    //     console.log('chatid : ', chatId);
    //     let cid
    //     if (chatId == '' || chatId == null || chatId == undefined) {
    //         cid = uuidv4();
    //         setChatId(cid);
    //     }
    //     else {
    //         cid = chatId;
    //     }
    //     const file = event.target.files[0];
    //     if (file && file.type.startsWith('image/')) {
    //         try {
    //             const language = localStorage.getItem('lang');
    //             const formData = new FormData();
    //             formData.append('file', file);
    //             formData.append('chat_id', cid);
    //             formData.append('language', language);
    //             formData.append('subscription', sanitizedSubscription)

    //             console.log('ccc: ', cid);

    //             const requestOptions = {
    //                 method: 'POST',
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`  // No need for 'Content-Type', FormData sets it automatically
    //                 },
    //                 body: formData
    //             };

    //             const response = await fetch(backURL + '/imgUpload', requestOptions);

    //             if (!response.ok) {
    //                 throw new Error('Failed to upload image');
    //             }

    //             const mess = await response.json();
    //             console.log('mess : ', mess);
    //             const newAnalysis = {
    //                 id: Date.now(),
    //                 image: URL.createObjectURL(file),  // Displaying the uploaded image in the frontend
    //                 response: mess.response
    //             };
    //             setImageAnalyses(prev => [newAnalysis, ...prev]);
    //             toast.update(toastgen, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });

    //         } catch (error) {
    //             console.error('Error uploading image response:', error);
    //             toast.update(toastgen, { render: "Failed to analysis response!", type: "error", isLoading: false, autoClose: 2000 });
    //         }
    //     } else {
    //         // toast.error('Please select a valid image file.');
    //         toast.update(toastgen, { render: "Please select a valid image file.!", type: "error", isLoading: false, autoClose: 2000 });
    //     }
    // };

    const handleFileUpload = async (event) => {
        console.log('chatid : ', chatId);
        let toastgen;

        let cid;
        if (!chatId) {
            cid = uuidv4();
            setChatId(cid);
        } else {
            cid = chatId;
        }

        const file = event.target.files[0];

        if (file) {
            const sizeInMB = (file.size / 1024 / 1024).toFixed(2);
            console.log('file size is :', sizeInMB);
            if (sizeInMB > 25) {
                toast.error(`File size shold be less 25 mb: ${file.name}`);
                return;
            }
            // Allowed MIME types for multimedia files
            const allowedMultimediaTypes = [
                'image/',       // All image types (e.g., jpg, png, gif, etc.)
                'video/',       // All video types (e.g., mp4, mkv, etc.)
                'audio/'        // All audio types (e.g., mp3, wav, etc.)
            ];

            // Excluded file types
            const excludedTypes = ['application/pdf', 'text/plain', 'application/msword', 'application/vnd.ms-excel'];

            const fileType = file.type;

            if (
                allowedMultimediaTypes.some(type => fileType.startsWith(type)) &&
                !excludedTypes.includes(fileType)
            ) {
                toastgen = toast.loading("Please wait...");
                try {
                    const language = localStorage.getItem('lang');
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('chat_id', cid);
                    formData.append('language', language);
                    formData.append('subscription', subscription);

                    console.log('ccc: ', cid);

                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}` // FormData handles the Content-Type header automatically
                        },
                        body: formData
                    };

                    const response = await fetch(backURL + '/imgUpload', requestOptions);

                    if (!response.ok) {
                        throw new Error('Failed to upload file');
                    }

                    const mess = await response.json();
                    console.log('Failed to upload file');
                    const newAnalysis = {
                        id: Date.now(),
                        file: URL.createObjectURL(file),
                        fileType: file.type.startsWith('image/')
                            ? 'image'
                            : file.type.startsWith('video/')
                                ? 'video'
                                : 'audio',
                        response: mess.response
                    };
                    setImageAnalyses(prev => [newAnalysis, ...prev]);

                    toast.update(toastgen, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
                } catch (error) {
                    console.error('Error uploading file response:', error);
                    toast.update(toastgen, { render: "Failed to process file!", type: "error", isLoading: false, autoClose: 2000 });
                }
            } else {
                toast.update(toastgen, {
                    render: "Unsupported file type! Please upload a valid multimedia file.",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000
                });
            }
        } else {
            toast.update(toastgen, { render: "No file selected!", type: "error", isLoading: false, autoClose: 2000 });
        }
    };

    const [languages, setLanguages] = useState([]);

    // Fetch languages from an API
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await fetch(backURL + "/languageGet"); // Replace with your API endpoint
                if (!response.ok) {
                    throw new Error("Failed to fetch languages");
                }
                const data = await response.json();
                if (data) {
                    setLanguages(data.msg);
                }
            } catch (error) {
                console.error("Error fetching languages:", error);
            }
        };

        fetchLanguages();
    }, []);


    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    function formatResponse(response) {
        // Replace both "CRITICAL WARNING:" and "ଗୁରୁତର ଚେତାବନୀ:" with a styled markdown equivalent
        const formattedResponse = response
            .replace(/CRITICAL WARNING:/g, '<span class="critical-warning">CRITICAL WARNING:</span>') //english
            .replace(/ଗୁରୁତର ଚେତାବନୀ:/g, "<span class ='critical-warning'>ଗୁରୁତର ଚେତାବନୀ:</span>") // odia
            .replace(/गंभीर चेतावनी:/g, '<span class ="critical-warning">गंभीर चेतावनी:</span>') //hindi
            .replace(/সমালোচনামূলক সতর্কতা:/g, '<span class ="critical-warning">সমালোচনামূলক সতর্কতা:</span>') //bangali
            .replace(/క్రిటికల్ వార్నింగ్:/g, '<span class ="critical-warning">క్రిటికల్ వార్నింగ్:</span>') //telgu
            .replace(/गंभीर चेतावणी:/g, '<span class ="critical-warning"> गंभीर चेतावणी:</span>') //marathi
            .replace(/சிக்கலான எச்சரிக்கை:/g, '<span class ="critical-warning">சிக்கலான எச்சரிக்கை:</span>') //tamil
            .replace(/જટિલ ચેતવણી:/g, '<span class ="critical-warning">જટિલ ચેતવણી:</span>') //gujarati
            .replace(/ಕ್ರಿಟಿಕಲ್ ವಾರ್ನಿಂಗ್:/g, '<span class ="critical-warning">ಕ್ರಿಟಿಕಲ್ ವಾರ್ನಿಂಗ್:</span>') //Kanada
            .replace(/Caution:/g, '<span class="caution-warning">Caution:</span>')
            .replace(/ସତର୍କତା:/g, '<span class="caution-warning">ସତର୍କତା:</span>')
            .replace(/सावधानी:/g, '<span class="caution-warning">सावधानी:</span>')
            .replace(/সতর্কতা:/g, '<span class="caution-warning">সতর্কতা:</span>')
            .replace(/జాగ్రత్త:/g, '<span class="caution-warning">జాగ్రత్త:</span>')
            .replace(/सावधगिरी:/g, '<span class="caution-warning">सावधगिरी:</span>')
            .replace(/எச்சரிக்கை:/g, '<span class="caution-warning">எச்சரிக்கை:</span>')
            .replace(/સાવધાન:/g, '<span class="caution-warning">સાવધાન:</span>')
            .replace(/ಎಚ್ಚರಿಕೆ:/g, '<span class="caution-warning">ಎಚ್ಚರಿಕೆ:</span>')

        return formattedResponse;
    }

    console.log('ansnsjslk : ', imageAnalyses)

    return (
        <div className="flex h-screen bg-gray-100">
            <ToastContainer
                position="top-left"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {/* Collapsible Sidebar */}
            <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out overflow-y-auto`}>
                <div className="p-4 flex justify-between items-center">
                    <button onClick={toggleSidebar} className="text-white">
                        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
                    </button>
                    {isSidebarVisible && (
                        <button onClick={handleLogout} className="text-sm">
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                            Logout
                        </button>
                    )}
                </div>
                {isSidebarVisible && (
                    <div className="p-4">
                        <button
                            onClick={handleNewChat}
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            New Chat
                        </button>
                        <select
                            id="path"
                            name="path"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onChange={handleNavigation}
                        >
                            <option value={subscription}>Navigate To</option>
                            {subscriptions &&
                                subscriptions.map((subscr) =>
                                    subscr.featureValue !== subscription ? (
                                        <option key={subscr.featureValue} value={subscr.featureValue}>
                                            {subscr.featureName}
                                        </option>
                                    ) : null
                                )}
                        </select>
                        <select id="language"
                            name="language"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onClick={handleLangChange}>
                            <option value={language}>Language</option>
                            {languages && (
                                languages.map((lang) => (
                                    <option key={lang.lang_code} value={lang.lang_code}>
                                        {lang.language}
                                    </option>
                                ))
                            )}
                        </select>
                        <div className='side_auto'>
                            {renderChats("Today", groupedData.today)}
                            {renderChats("Yesterday", groupedData.lastSevenDays)}
                            {renderChats("Older", groupedData.older)}
                            {page < endPage && (
                                <button className='show-chat' onClick={nextChat}>See Older Chat</button>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col p-4">
                <div className="mb-4">
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        accept="image/*,video/*,audio/*"
                        className="hidden"
                    />
                    <button
                        onClick={triggerFileInput}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Multimedia File
                    </button>
                </div>

                <div className="flex-1 overflow-auto">
                    {imageAnalyses.length === 0 ? (
                        <p className="text-gray-500">Upload an image/video to see the analysis result.</p>
                    ) : (
                        imageAnalyses.map(analysis => (
                            <div key={analysis.id} className="bg-white rounded-lg shadow p-4 mb-4 flex">
                                {analysis.fileType === 'image' ? (
                                    <div className="w-1/2" onClick={() => handleAnalyseOpenImage(analysis.file)}>
                                        <img
                                            src={analysis.file}
                                            alt="Uploaded Image"
                                            className="max-w-full max-h-[30vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                        />
                                    </div>
                                ) : analysis.fileType === 'video' ? (
                                    <div className="w-1/2">
                                        <video
                                            src={analysis.file}
                                            controls
                                            className="max-w-[80%] max-h-[40vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                        />
                                    </div>
                                ) : analysis.fileType === 'audio' ? (
                                    <div className="w-1/2">
                                        <audio
                                            src={analysis.file}
                                            controls
                                            className="w-full rounded-lg shadow-lg"
                                        />
                                    </div>
                                ) : (
                                    <div className="w-1/2 text-red-500 font-bold">
                                        Unsupported file type
                                    </div>
                                )}

                                <div className="w-full overflow-auto">
                                    <h3 className="text-lg font-semibold mb-2">Image Analysis :</h3>

                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]} // This enables HTML parsing
                                        children={formatResponse(analysis.response)}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                    {messages.map(mess => (
                        <div key={mess.mid} className="bg-white rounded-lg shadow p-4 mb-4 flex">
                            {/* <div className="w-1/2" onClick={() => handleAnalyseOpenImage(mess.analyzeImg)}>
                                <img
                                    src={mess.analyzeImg}
                                    alt="Uploaded"
                                    className="max-w-full max-h-[30vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                />
                            </div> */}
                            <div className="w-1/2">
                                {mess.analyzeImg.endsWith('.mp4') || mess.analyzeImg.endsWith('.mkv') || mess.analyzeImg.endsWith('.avi') ? (
                                    <video
                                        src={mess.analyzeImg}
                                        controls
                                        className="max-w-[80%] max-h-[40vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                ) : mess.analyzeImg.endsWith('.mp3') || mess.analyzeImg.endsWith('.wav') ? (
                                    <audio
                                        src={mess.analyzeImg}
                                        controls
                                        className="w-full rounded-lg shadow-lg"
                                    >
                                        Your browser does not support the audio tag.
                                    </audio>
                                ) : (
                                    <div onClick={() => handleAnalyseOpenImage(mess.analyzeImg)}>
                                        <img
                                            src={mess.analyzeImg}
                                            alt="Uploaded"
                                            className="max-w-[80%] max-h-[40vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="w-full overflow-auto">
                                <h3 className="text-lg font-semibold mb-2">Multimedia Analysis : </h3>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]} // This enables HTML parsing
                                    children={formatResponse(mess.response)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={analyzeImage}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
            </div>


            <style jsx>{`
                .critical-warning {
                    color: red;
                    font-weight: bold;
                    background-color: #ffe6e6;
                    padding: 0.2em;
                    border-radius: 4px;
                }
                .caution-warning {
                    color: orange;
                    font-weight: bold;
                    background-color: #fff4e5;
                    padding: 0.2em;
                    border-radius: 4px;
    }
            `}</style>
        </div>

    );
};

export default ImageAnal;